import { useNavigate } from "react-router-dom";

export default function LogoutButton(props) {
    const navigate = useNavigate();
    const handleLogout = () => {
        // Delete the token from local storage
        console.log("I am the logout handler")
        localStorage.removeItem("token");

        // Navigate the user to the login page
        navigate("/");
    };

    return <button
        type="button"
        className="btn btn-danger text-white loginbtn"
        onClick={handleLogout}
    >
        Logout
    </button>
}