import React, { useState, useEffect } from "react";
import instance from "../utils/api";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../utils/loading";

const FinancialInfo = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await instance.get("/students/getCurrentCourses");
        setCourses(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourses();
  }, []);

  const numCourses = courses.length;
  const tuition = numCourses * 1000;
  const serviceFee = 100;
  const recreationFee = 50;
  const healthInsurance = 250;
  const totalFee = tuition + serviceFee + recreationFee + healthInsurance;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container my-5">
      <h3 className="text-center mb-4">Financial Information</h3>
      <Table bordered hover>
        <tbody>
          <tr>
            <th>Number of courses</th>
            <td>{numCourses}</td>
          </tr>
          <tr>
            <th>Tuition</th>
            <td>${tuition}</td>
          </tr>
          <tr>
            <th>Service Fee</th>
            <td>${serviceFee}</td>
          </tr>
          <tr>
            <th>Recreation Fee</th>
            <td>${recreationFee}</td>
          </tr>
          <tr>
            <th>Foreign Health Insurance</th>
            <td>${healthInsurance}</td>
          </tr>
          <tr>
            <th>Total Fee</th>
            <td>${totalFee}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default FinancialInfo;
