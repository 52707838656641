import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import instance from "../utils/api";
import LoadingSpinner from "../utils/loading";
import "../css/timetable.css"

const localizer = momentLocalizer(moment);

const Timetable = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await instance.get("/students/getCurrentCourses");
        const courseData = response.data;

        // Filter out courses with empty schedules
        const filteredCourseData = courseData.filter(
          (course) => course.schedule.length > 0
        );

        // Transform course schedule data into events
        const courseEvents = filteredCourseData
          .map((course) => {
            const { subject, number, schedule } = course;
            return schedule.map((s, index) => {
              // Convert start time to string and add leading 0 if it has length 3
              const startTime =
                String(s.start_time).length === 3
                  ? `0${s.start_time}`
                  : String(s.start_time);
              // Convert end time to string and add leading 0 if it has length 3
              const endTime =
                String(s.end_time).length === 3
                  ? `0${s.end_time}`
                  : String(s.end_time);

              // Get days of the week for the recurring events
              const daysOfWeek = getDaysOfWeek(s.day);

              // Create recurring events for each day of the week
              return {
                id: index,
                title: `${subject} ${number}`,
                start: moment()
                  .day(daysOfWeek[0])
                  .hour(Number(startTime.slice(0, 2)))
                  .minute(Number(startTime.slice(2)))
                  .toDate(),
                end: moment()
                  .day(daysOfWeek[0])
                  .hour(Number(endTime.slice(0, 2)))
                  .minute(Number(endTime.slice(2)))
                  .toDate(),
                allDay: false,
              };
            });
          })
          .flat();

        // console.log(courseEvents);
        setEvents(courseEvents);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchCourseData();
  }, []);

  // Helper function to convert day string to daysOfWeek array
  const getDaysOfWeek = (day) => {
    const daysOfWeekMap = {
      M: 1,
      T: 2,
      W: 3,
      R: 4,
      F: 5,
    };

    return [daysOfWeekMap[day]];
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
      <div className="ps-5 pt-5 display-6"> Time Table</div>
      {!isLoading && events.length > 0 && (
        <Calendar
          localizer={localizer}
          events={events}
          defaultView="week"
          startAccessor="start"
          endAccessor="end"
          style={{ height: "auto", width: "-webkit-fill-available"}}
          className="timetable-calendar p-5"
        />
      )}
    </div>
  );
};

export default Timetable;
