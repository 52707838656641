import React, { useState } from "react";
import instance from "../utils/api";

const SignUpForm = ({ toggleSignUp }) => {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    isAdmin: false,
  });

  const [alertMsg, setAlertMsg] = useState(<></>);

  const handleSubmit = (e) => {
    e.preventDefault();
    instance
      .post("/createAccount", formData)
      .then((response) => {
        setAlertMsg(
          <div className="alert alert-success" role="alert">
            Sign up successful!
          </div>
        );

        setFormData({
          firstName: "",
          lastName: "",
          username: "",
          password: "",
          isAdmin: false,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          //setErrorMessage(error.response.data);
        } else {
          setAlertMsg(
            <div className="alert alert-danger" role="alert">
              Sign up failed!
            </div>
          );
        }
      });
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign Up</h3>
          <div className="text-center">
            Already registered?{" "}
            <span
              className="link-primary"
              onClick={toggleSignUp}
              style={{ cursor: "pointer" }}
            >
              Login
            </span>
          </div>
          <div className="form-group mt-3">
            <label>First Name</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="e.g Jane"
              name="firstName"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="e.g Doe"
              name="lastName"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({ ...formData, lastName: e.target.value })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Username"
              name="username"
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <div className="py-2">{alertMsg}</div>
        </div>
      </form>
    </div>
  );
}

export default SignUpForm;
