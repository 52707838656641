import React, { useState } from "react";
import LoginForm from "./Login";
import SignUpForm from "./SignUp";

const AuthPage = (props) => {
  const [showSignUp, setShowSignUp] = useState(false);

  const toggleSignUp = () => {
    setShowSignUp(!showSignUp);
  };

  return (
    <div className="Auth-page-container">
      {showSignUp ? (
        <SignUpForm toggleSignUp={toggleSignUp} />
      ) : (
        <LoginForm toggleSignUp={toggleSignUp} isAdmin={props.isAdmin}/>
      )}
    </div>
  );
};

export default AuthPage;
