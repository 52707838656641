import React, { useState } from "react";
import instance from "../utils/api";
import CourseItem from "./CourseItem";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  Modal,
} from "react-bootstrap";

const Search = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;

  const handleSearch = () => {
    if (searchTerm) {
      setLoading(true);
      instance
        .get(`/searchCourse?query=${searchTerm}`)
        .then((response) => setCourses(response.data))
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleAddCourse = (course) => {
    console.log(course)
    instance
      .post(`/students/addCurrentCourse/${course._id}`)
      .then((response) => {
        setModalMessage(`${course.subject} ${course.number} has been added to your courses.`);
        setShowModal(true);
      })
      .catch((error) => {
        setModalMessage(error.response.data);
        setShowModal(true);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <InputGroup className="mb-3" style={{ marginTop: "20px" }}>
            <FormControl
              placeholder='Search for courses. Example: "COMP", "COMP 1000", "ECON 1020"'
              aria-label="Search for courses"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <Button variant="outline-secondary" onClick={handleSearch}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                  />
                  Loading...
                </>
              ) : (
                "Search"
              )}
            </Button>
          </InputGroup>
          {loading ? (
            <ListGroup className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </ListGroup>
          ) : (
            <ListGroup>
              {courses.length === 0 && searchTerm && (
                <ListGroupItem>
                  No courses found for "{searchTerm}"
                </ListGroupItem>
              )}
              {courses.map((course) => (
                <CourseItem
                  key={course._id}
                  course={course}
                  handleAddCourse={handleAddCourse}
                />
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Registration Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Search;
