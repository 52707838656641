import "../css/sidebar.css"
import brandLogo from "../brand-logo.svg"
import LogoutButton from "./LogoutButton";

const SIZE_X = 20;
const SIZE_Y = 20;


export default function Sidebar(props) {

    const onHomeButtonClick = (e) => {
        props.setActiveLink("Home");
    }

    const onRegisterButtonClick = (e) => {
        props.setActiveLink("Register");
    }

    const onTimeTableButtonClick = (e) => {
        props.setActiveLink("Time table");
    }

    const onAcademicInfoButtonClick = (e) => {
        props.setActiveLink("Academic Information");
    }

    const onFinancialInfoButtonClick = (e) => {
        props.setActiveLink("Financial Information");
    }

    return (
        <div className="sidebar-container d-flex flex-column flex-shrink-0 bg-dark">
            <a href="/homepage" className="d-flex flex-column align-items-center p-3 mb-3 mb-md-0 me-md-auto link-light text-decoration-none">
                <img className="p-3 brand-logo" src={brandLogo} alt="My Icon Description" />
                <span className="fs-4 text-wrap text-center fw-bolder">MUN Course Registration Portal</span>
            </a>
            <div className="px-3">
                <hr className="border border-secondary border-2" />
            </div>
            <ul className="nav nav-pills flex-column mb-auto p-3">
                <li className="nav-item pb-1">
                    <a href="#" className={"nav-link " + (props.activeLink == "Home" ? "active" : "") + " link-light d-flex flex-row"} onClick={onHomeButtonClick}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width={SIZE_X} height={SIZE_Y} fill="currentColor" className="bi bi-house-door-fill" viewBox="0 0 16 16">
                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                            </svg>
                        </div>
                        <div className="text-wrap ps-3">Home</div>
                    </a>
                </li>
                <li className="nav-item pb-1">
                    <a href="#" className={"nav-link " + (props.activeLink == "Register" ? "active" : "") + " link-light d-flex flex-row"} onClick={onRegisterButtonClick}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width={SIZE_X} height={SIZE_Y} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                        <div className="text-wrap ps-3">Register</div>
                    </a>
                </li>
                <li className="nav-item pb-1">
                    <a href="#" className={"nav-link " + (props.activeLink == "Time table" ? "active" : "") + " link-light d-flex flex-row"} onClick={onTimeTableButtonClick}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width={SIZE_X} height={SIZE_Y} fill="currentColor" className="bi bi-calendar-event-fill" viewBox="0 0 16 16">
                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-3.5-7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        </div>
                        <div className="text-wrap ps-3">Time Table</div>
                    </a>
                </li>
                <li className="nav-item pb-1">
                    <a href="#" className={"nav-link " + (props.activeLink == "Academic Information" ? "active" : "") + " link-light d-flex flex-row"} onClick={onAcademicInfoButtonClick}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width={SIZE_X} height={SIZE_Y} fill="currentColor" className="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                                <path d="M6 12v-2h3v2H6z" />
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                            </svg>
                        </div>
                        <div className="text-wrap ps-3">Academic Information</div>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="#" className={"nav-link " + (props.activeLink == "Financial Information" ? "active" : "") + " link-light d-flex flex-row"} onClick={onFinancialInfoButtonClick}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width={SIZE_X} height={SIZE_Y} fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
                                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                            </svg>
                        </div>
                        <div className="text-wrap ps-3">Financial Information</div>
                    </a>
                </li>
            </ul>
            <div className="px-3">
                <hr className="border border-secondary border-2" />
            </div>
            <div className="p-3">
                <a href="#" className="d-flex align-items-center link-light text-decoration-none" id="dropdownUser2">
                    <div className="rounded-circle me-2 p-2 bg-secondary" width="30" height="30">
                        {(props.studentInfo.firstName ? props.studentInfo.firstName[0] : '') + (props.studentInfo.lastName ? props.studentInfo.lastName[0] : '')}
                    </div>
                    <strong className="text-wrap">
                        {props.studentInfo.firstName ? props.studentInfo.firstName : 'First Name Not Available'},
                        {props.studentInfo.lastName ? props.studentInfo.lastName : 'Last Name Not Available'}
                    </strong>
                </a>

                <div className="pt-3 pb-1 d-grid">
                    <LogoutButton />
                </div>
            </div>
        </div>
    );
}
