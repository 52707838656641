import React, { useState, useEffect } from "react";
import instance from "../utils/api";
import { Card, Spinner, Alert, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function Studentinfo() {
  const [data, setData] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDeleteCourse, setConfirmDeleteCourse] = useState(null);

  useEffect(() => {
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    Promise.all([
      instance.get("/students/getCurrentCourses"),
      instance.get("/students/getStudentInfo"),
    ])
      .then(([coursesResponse, studentResponse]) => {
        setData(coursesResponse.data);
        setStudentInfo(studentResponse.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Alert variant="warning">No data found.</Alert>
      </div>
    );
  }

  const handleDelete = (id) => {
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    instance
      .delete(`/students/removeCurrentCourse/${id}`)
      .then(() => {
        // remove the deleted course from the state
        setData((prevData) => prevData.filter((item) => item._id !== id));
        setConfirmDeleteCourse(null);
      })
      .catch((error) => {
        console.log(error);
        // show an error message to the user
        alert("Failed to delete the course.");
      });
  };

  return (
    <div className="container px-5 pt-4">
      <h3 className="my-3">Registered Courses</h3>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5>
            Student: {studentInfo.firstName} {studentInfo.lastName}
          </h5>
        </div>
      </div>
      <div className="d-flex flex-column">
        {data.map((item) => (
          <div key={item._id} className="mb-3 card-container">
            <Card>
              <Card.Body>
                <Card.Title>{item.subject}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Course code: {item.number}
                </Card.Subtitle>
                <Card.Text>{item.name}</Card.Text>
              </Card.Body>
              <div className="d-flex justify-content-end p-3">
                <FontAwesomeIcon
                  icon={faTrash}
                  className="plus-icon"
                  onClick={() => setConfirmDeleteCourse(item)}
                />
              </div>
            </Card>
          </div>
        ))}
      </div>
      <Modal
        show={Boolean(confirmDeleteCourse)}
        onHide={() => setConfirmDeleteCourse(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this course? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmDeleteCourse(null)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDelete(confirmDeleteCourse._id)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Studentinfo;
