import { Link, useNavigate } from "react-router-dom";
import "../css/navbar.css";

const Navigationbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Delete the token from local storage
    console.log("I am the logout handler")
    localStorage.removeItem("token");

    // Navigate the user to the login page
    navigate("/");
  };

  return (
    <>
      <div className="loginBar container-fluid bg-secondary d-flex flex-wrap align-items-center">
        <nav className="navbar-dark d-inline-flex align-items-center flex-grow-1">
          <Link to="/homepage" className="navbar-brand">
            {/* <img
              id="nav-logo"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlq5HBm3PMYychM_62YJmJJWksFJgcvrTaoA&usqp=CAU"
              alt="nav-logo"
              className="img-fluid d-inline-block align-baseline me-2"
            />
            <span className="nav-title">Regi</span>
            <span className="nav-title-to">Flow</span> */}
          </Link>
        </nav>
        <div className="d-inline-flex m-2 logincategory">
          <div className="">
            {/* <button
              type="button"
              className="btn btn-outline-light btn-lg text-white loginbtn me-2"
              onClick={handleLogout}
            >
              Logout
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigationbar;
