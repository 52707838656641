import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import instance from "../utils/api";
import LoadingSpinner from "../utils/loading";

const AcademicInfo = () => {
  const [courses, setCourses] = useState([]);
  const [cpa, setCpa] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;

  useEffect(() => {
    const getPoints = (grade) => {
      const gradeToPoints = {
        "80-100": 4,
        "65-79": 3,
        "55-64": 2,
        "50-54": 1,
        "0-49": 0,
      };

      for (const gradeRange in gradeToPoints) {
        const [min, max] = gradeRange.split("-").map(Number);
        if (grade >= min && grade <= max) {
          return gradeToPoints[gradeRange];
        }
      }

      return NaN;
    };

    const calculateGPA = (courses) => {
      let totalCredits = 0;
      let weightedGradePoints = 0;

      // Loop through each course object in the array
      for (let i = 0; i < courses.length; i++) {
        const { credit, grade } = courses[i];

        // Calculate weighted grade points by multiplying credits with grade
        const gradePoints = credit * getPoints(grade);
        weightedGradePoints += gradePoints;

        // Add credits to total credits
        totalCredits += parseInt(credit);
      }

      // Calculate GPA by dividing weighted grade points by total credits
      const gpa = weightedGradePoints / totalCredits;

      // Return the calculated GPA rounded to two decimal places
      return gpa.toFixed(2);
    };

    // Fetch completed courses data from API endpoint
    instance
      .get("/students/getCompletedCourses")
      .then((response) => {
        setCourses(response.data);
        setCpa(calculateGPA(response.data));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching completed courses:", error);
      });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="text-center">
      <h2>Completed Courses</h2>
      <div className="mx-auto" style={{ maxWidth: "800px" }}>
        {/* Set a maximum width of 800px and center the content */}
        {isLoading ? (
          <div className="d-flex justify-content-center mt-5">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            ...
            <Table className="mt-4">
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Course</th>
                  <th style={{ whiteSpace: "nowrap" }}>Title</th>{" "}
                  {/* Add inline style for nowrap */}
                  <th>Credit Hours</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.id}>
                    <td>{course.subject}</td>
                    <td>{course.number}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{course.name}</td>{" "}
                    {/* Add inline style for nowrap */}
                    <td>{parseInt(course.credit).toFixed(2)}</td>
                    <td>{course.grade}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            ...
            <h4 className="mt-4">Cumulative GPA: {cpa}</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default AcademicInfo;
