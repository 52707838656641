import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../utils/api";


const LoginForm = ({ toggleSignUp, isAdmin=false }) => {


  const navigate = useNavigate();
  const [alertMsg, setAlertMsg] = useState(<></>);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const pathToApi = isAdmin ? "/adminLogin" : "/login";

  if (isAdmin) {
    if (localStorage.getItem('admin-token')) {
      window.open('/admin', '_self');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    instance
      .post(pathToApi, formData)
      .then((response) => {
        if (isAdmin) {
          localStorage.setItem("admin-token", response.data);
          window.open('/admin', '_self');
        } else {
          localStorage.setItem("token", response.data);
        }
        
        if (!isAdmin) {
          navigate("/homepage");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          //   setErrorMessage(error.response.data);
        } else {
          setAlertMsg(<div className="alert alert-danger" role="alert">
            Login failed! Incorrect username or password.
          </div>);
          if (isAdmin) 
          {
            localStorage.removeItem('admin-token');
          } 
          else 
          {
            localStorage.removeItem('token');
          }
          console.log(error);
        }
      });
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">
            {isAdmin ? "Admin Login" : "Login"}
          </h3>
          {!isAdmin && (
            <div className="text-center">
              Not registered yet?{" "}
              <span
                className="link-primary"
                onClick={toggleSignUp}
                style={{ cursor: "pointer" }}
              >
                Create Account
              </span>
            </div>
          )}
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter username"
              name="username"
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              name="password"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <div className="py-2">{alertMsg}</div>
          {isAdmin && (
            <>
              <br />
            </>
          )}
          {!isAdmin && (
            <>
              <p className="text-center mt-0 p-0">
                <a href="/admin/login">Login as admin</a>
              </p>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
