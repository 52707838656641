import axios from 'axios';
import { useEffect, useState } from 'react';
import '../css/admin.css'

const API_URL = "https://server.zakwanashfaq.com/courseRegApp/";

function AdminNavbar(props){
    const handleClick = (event) => {
        console.log("ddd")
        event.preventDefault();
        window.location.href = "admin/student-profile-builder/";
    }

    const handleLogout = (event) => {
        event.preventDefault();
        window.location.href = "/";
        localStorage.removeItem('admin-token')
    }

    return <nav className="custom-navbar" >
        <div className="container-fluid py-4 px-5 d-flex justify-content-between">
            <a className="navbar-brand fs-2 text-white">Admin Dashboard</a>
            <div className="d-flex" role="search">
                <button className="btn btn-outline-success text-white mx-2" onClick={handleClick}>Student Profile Builder</button>
                <button className="btn btn-danger text-white" onClick={handleLogout}>Logout</button>
            </div>
        </div>
    </nav>
}

function UserRecordItem(props) {
    const [show, setShow] = useState(true);

    const handleDelete = () => {
        const pathToApi =  API_URL + "/admin/delete/" + props.user.username;
        axios.delete(pathToApi, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('admin-token')}`
            }
        }).then(response => {
            setShow(false);
        }).catch(error => {
            console.log(error);
        });
    }

    const handleEdit = () => {
        props.setSelectedUsername({
            username: props.user.username,
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            currentCourses: props.user.currentCourses
        })
    }

    if (!show) {
        return <></>
    }

    if (props.user.isAdmin) {
        return <tr className="table-warning">
            <th scope="col-3">{props.user.username}</th>
            <th scope="col-3">{props.user.firstName}</th>
            <th scope="col-3">{props.user.lastName}</th>
            <th scope="col-3">(Cannot modify admin)</th>
        </tr>
    }

    return <tr className=''>
      <th scope="col-3">{props.user.username}</th>
      <th scope="col-3">{props.user.firstName}</th>
      <th scope="col-3">{props.user.lastName}</th>
      <th scope="col-3">
        <button className='btn btn-primary mx-1' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleEdit}>Edit</button>
        <button className='btn btn-danger'  onClick={handleDelete}>Delete</button>
      </th>
    </tr>
}


function UserRecordTable(props) {
    return <table className="table table-hover">
        <thead>
            <tr>
                <th scope="col-6">username</th>
                <th scope="col-3">First name</th>
                <th scope="col-3">Last name</th>
                <th scope="col-3">Actions</th>
            </tr>
        </thead>
        <tbody>
            {props.children}
        </tbody>
    </table>
}

function UserProfileModalCourseItems(props) {
    const courseID = props.courseId;
    const remove_course = () => {
        props.removeCourse(courseID)
    };
    const recordStr = props.courseHash[courseID].subject + " " + props.courseHash[courseID].number
    return <div className='px-3 pb-1'>
        <div className="card">
            <div className="card-body d-flex justify-content-between">
                <div>
                    {recordStr}
                </div>
                <div>
                    <button className='btn btn-danger' onClick={remove_course}>
                        Remove Course
                    </button>
                </div>
            </div>
        </div>
    </div>
}

function UserProfileModal(props) {
    const [userName, setUserName] = useState(props.userData.username);
    const [firstName, setFirstName] = useState(props.userData.firstName);
    const [lastName, setLastName] = useState(props.userData.lastName);
    const [courseList, setCourseList] = useState(props.userData.currentCourses);
    const [courseHash, setCourseHash] = useState({});
    const [updateAlert, setUpdateAlert] = useState(<></>);

    const removeCourse = (courseID) => {
        const new_currentCourses =  courseList.filter(item => item !== courseID);
        console.log(courseList);
        setCourseList(new_currentCourses);
    }

    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    }

    const handleSaveChanges = async () => {
        axios.put(API_URL + "admin/updateUser", {
            userToBeUpdated: props.userData.username,
            userName,
            firstName,
            lastName,
            currentCourses: courseList
        }, {
            headers: {
            authorization: `Bearer ${localStorage.getItem('admin-token')}`
        }}).then(response => {
            setUpdateAlert(<div className="alert alert-success" role="alert">
                Updated user!
            </div>)
            window.location.reload();
        }).catch(error => {
            setUpdateAlert(<div className="alert alert-danger" role="alert">
                Error: Could not be updated
            </div>)
        });
    }

    const getCourseList = () => {
        axios.get(API_URL + "/students/getAllCourses", {
            headers: {
            authorization: `Bearer ${localStorage.getItem('admin-token')}`
        }}).then(response => {
            let course_hash = {};
            response.data.forEach(record => {
                course_hash[record._id] = record;
            })
            setCourseHash(course_hash);
        }).catch(error => {
            console.log("Error: Courses could not be loaded.");
        });
    }


    const onClose = () => {
        setUserName(props.userData.username);
        setFirstName(props.userData.firstName);
        setLastName(props.userData.lastName);
        setCourseList(props.userData.currentCourses);
    }

    useEffect(prevState => {
        getCourseList();
    }, [])

    useEffect((prevState)=> {
        setUserName(props.userData.username);
        setFirstName(props.userData.firstName);
        setLastName(props.userData.lastName);
        setCourseList(props.userData.currentCourses);
    }, [props.userData.username, props.userData.firstName, props.userData.lastName, props.userData.currentCourses])

    return <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Editing user {props.userData.username}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button>
                </div>
                <div className="modal-body">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon2">Edit username</span>
                        <input type="text" className="form-control" value={userName} onChange={handleUserNameChange} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon2">Edit first name</span>
                        <input type="text" className="form-control" value={firstName} onChange={handleFirstNameChange} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon2">Edit last name</span>
                        <input type="text" className="form-control" value={lastName} onChange={handleLastNameChange} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    </div>
                </div>
                <div>
                    {courseList.map(course => <UserProfileModalCourseItems key={course} courseId={course} removeCourse={removeCourse} courseHash={courseHash}/>)}
                </div>
                <div className='px-3'>{updateAlert}</div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}>Close</button>
                    <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save changes</button>
                </div>
            </div>
        </div>
    </div>
}

export function AdminPage(props){
    if (!localStorage.getItem('admin-token')) {
        window.open('/admin/login/', '_self');
    }
    
    let [all_users, set_all_users] = useState([]);

    useEffect(() => {
        axios.get(API_URL + '/getAllStudents', {
            headers: {
                authorization: `Bearer ${localStorage.getItem('admin-token')}`
            }
        }).then(response => {
            set_all_users(response.data);
        }).catch(error => {
            console.log(error);
            localStorage.removeItem('admin-token')
            window.location.href = "/admin/login"
        });
    }, []);

    let [search_string, set_search_string] = useState("");
    const handleSearchStringChange = (event) => {
        set_search_string(event.target.value);
    }

    const [selectedUserName, setSelectedUsername] = useState({
        username: "NULL ERROR",
        firstName: "NULL ERROR",
        lastName: "NULL ERROR",
        currentCourses: []
    });

    return <div className="admin-Page">
        <UserProfileModal userData={selectedUserName}/>
        <AdminNavbar />
        <div className="container-lg top-padding">
            <div className="row">
                <div className="pt-5">
                    <a className="navbar-brand fs-4">Users</a>
                    <div className="input-group my-3">
                        <span className="input-group-text">Search by username</span>
                        <input type="text" className="form-control" aria-label="Recipient's username" 
                        aria-describedby="button-addon2" value={search_string} onChange={handleSearchStringChange}/>
                    </div>
                    <div className='pt-1'>
                        <UserRecordTable>
                            {all_users
                                .filter(user => user.username.toLowerCase().includes(search_string.toLowerCase()))
                                .map(user => <UserRecordItem key={user._id} user={user} setSelectedUsername={setSelectedUsername} />)
                            }
                        </UserRecordTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
}