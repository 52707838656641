import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import "../css/categorybar.css"
import brand_logo from "../brand-logo.svg"
import LogoutButton from "./LogoutButton";

function CategoryBar({ activeLink, setActiveLink }) {
  useEffect(() => {
    let prev = window.scrollY;
    const scrolling = () => {
      let current = window.scrollY;
      const catBar = document.getElementById("categoryBar");
      if (prev > current && current < 10) {
        if (catBar) {
          catBar.style.top = "85px";
        }
      } else {
        if (catBar) {
          document.getElementById("categoryBar").style.top = "0";
        }
      }
      prev = current;
    };
    document.addEventListener("scroll", scrolling);
    return () => document.removeEventListener("scroll", scrolling);
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const LOGO_SIZE = 50;

  return (
    <Navbar bg="light" expand="lg" className="category-bar-container p-0 m-0">
      <img className="ps-3" src={brand_logo}  height={LOGO_SIZE} width={LOGO_SIZE}/>
      <div className="fw-bold">
        MUN Course Registration Portal
      </div>
      <Navbar.Toggle aria-controls="navbarNavDropdown" className="mx-3 my-3"/>
      
      <Navbar.Collapse id="navbarNavDropdown">
        <Nav className="mx-auto bg-warning category-dropdown-container p-2">
          <Nav.Link
            className={"btn btn-dark m-1 text-light"}
            onClick={() => handleLinkClick("Home")}
            type="button" 
            active={activeLink === "Home"}
          >
            Home
          </Nav.Link>
          <Nav.Link
            href="#Register"
            className={"btn btn-dark m-1 text-light"}
            onClick={() => handleLinkClick("Register")}
            active={activeLink === "Register"}
          >
            Register
          </Nav.Link>
          <Nav.Link
            href="#Time table"
            className="btn btn-dark m-1 text-light"
            onClick={() => handleLinkClick("Time table")}
          >
            Time Table
          </Nav.Link>
          <Nav.Link
            href="#Academic Information"
            className="btn btn-dark m-1 text-light"
            onClick={() => handleLinkClick("Academic Information")}
            active={activeLink == "Academic Information"}
          >
            Academic Information
          </Nav.Link>
          <Nav.Link
            href="#Financial Information"
            className="btn btn-dark text-light m-1"
            onClick={() => handleLinkClick("Financial Information")}
            active={activeLink == "Financial Information"}
          >
            Financial Information
          </Nav.Link>
          <div className="m-1 d-grid pt-3">
            <LogoutButton />
          </div>
        </Nav>
      </Navbar.Collapse>
</Navbar>

  );
}

export default CategoryBar;
