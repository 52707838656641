import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthPage from "./components/Auth";
import { AdminPage } from "./components/AdminPage";
import { ProfileBuilderPage } from "./components/ProfileBuilder";
import Homepage from "./components/Homepage";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<AuthPage />} />
        <Route path="/homepage" element={<Homepage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/student-profile-builder" element={<ProfileBuilderPage />} />
        <Route path="/admin/login" element={<AuthPage isAdmin={true}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
