import axios from "axios";
import { useState } from "react";

const ENUM = {
    STUDENT: 0,
    ADMIN: 1
}

function ProfileBuilderNavbar(props){
    function handleGoBack() {
        window.location.href = '/admin';
    }

    const handleLogout = (event) => {
        event.preventDefault();
        window.location.href = "/";
        localStorage.removeItem('admin-token')
    }

    return <nav className="custom-navbar bg-dark" >
        <div className="container-fluid py-4 px-5 d-flex justify-content-between">
                <div className="navbar-brand fs-2 text-white">
                    <i className="bi bi-arrow-left-circle me-3" onClick={handleGoBack}></i>
                    <span>Student Profile Builder</span>
                </div>
                <div className="d-flex" role="search">
                    <button className="btn btn-danger text-white" onClick={handleLogout}>Logout</button>
                </div>
        </div>
    </nav>
}

export function ProfileBuilderPage(props) {
    const [username, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [isAdminSelected, setAdminStatus] = useState(ENUM.STUDENT);
    const [actionMsg, setActionMsg] = useState(<></>);

    const handlecreateUser = () => {
        const pathToApi =  "https://server.zakwanashfaq.com/courseRegApp/createAccount";
        axios.post(pathToApi, {
            username,
            firstName,
            lastName,
            password,
            isAdmin: (isAdminSelected === ENUM.ADMIN),
            currentCourses: [],
            completedCourses: [],
        }, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('admin-token')}`
            }
        }).then(response => {
            setActionMsg(<div className="alert alert-success" role="alert">
                User {username} is created successfully.
            </div>)
        }).catch(error => {
            setActionMsg(<div className="alert alert-danger" role="alert">
                User {username} already exists or could not be created.
            </div>)
        });
    }    

    return <div>
        <ProfileBuilderNavbar />
        <div className="container-lg top-padding-profile">
            <div className="row fs-3 p-3">
                Create a new profile
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">First name</span>
                        <input type="text" className="form-control" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Last name</span>
                        <input type="text" className="form-control" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Username</span>
                        <input type="text" className="form-control" value={username} onChange={e => setUserName(e.target.value)} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Password</span>
                        <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Confirm password</span>
                        <input type="password" className="form-control" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div> */}
                <div className="col-4">
                    <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                        <input type="checkbox" checked={isAdminSelected === ENUM.ADMIN} className="btn-check" onChange={e => setAdminStatus(ENUM.ADMIN)} id="btncheck1" autoComplete="off" />
                        <label className="btn btn-outline-primary" htmlFor="btncheck1">Admin</label>

                        <input type="checkbox" checked={isAdminSelected !== ENUM.ADMIN} className="btn-check" onChange={e => setAdminStatus(ENUM.STUDENT)} id="btncheck2" autoComplete="off" />
                        <label className="btn btn-outline-primary" htmlFor="btncheck2">Student</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3 pt-3">
                    <button className="btn btn-success" onClick={handlecreateUser}>
                        Create Account
                    </button>
                </div>
            </div>
            <div className="row p-3">
                {actionMsg}
            </div>
        </div>
    </div>
}