import React, { useState } from "react";
import { ListGroupItem, Row, Col, Table, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const CourseItem = ({ course, handleAddCourse }) => {
  const [isAdding, setIsAdding] = useState(false);
  const hasSchedule = course.schedule && course.schedule.length > 0;

  const handleOnClick = async () => {
    setIsAdding(true);
    await handleAddCourse(course);
    setIsAdding(false);
  };

  const formatTime = (time) => {
    // Convert time string to Date object
    const date = new Date();
    date.setHours(Math.floor(time / 100));
    date.setMinutes(time % 100);

    // Format Date object to display in 24-hour system
    return date.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <ListGroupItem className="course-list-item">
      <Row>
        <Col>
          <div className="d-flex flex-column">
            <span>{`${course.subject} ${course.number}: ${course.name}`}</span>
            <span>Instructor: {course.instructor}</span>
            <span>Room: {course.room}</span>
            <span>Credits: {course.credit}</span>
          </div>
        </Col>
        <Col xs={1}>
          {isAdding ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <FontAwesomeIcon
              icon={faPlus}
              className="plus-icon"
              onClick={handleOnClick}
              style={{ cursor: "pointer" }}
            />
          )}
        </Col>
      </Row>
      {hasSchedule && (
        <Row>
          <Col>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </tr>
              </thead>
              <tbody>
                {course.schedule.map((scheduleItem) => (
                  <tr key={scheduleItem.day}>
                    <td>{scheduleItem.day}</td>
                    <td>{formatTime(scheduleItem.start_time)}</td>
                    <td>{formatTime(scheduleItem.end_time)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};

export default CourseItem;
