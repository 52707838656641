import React, { useEffect, useState } from "react";
import Navigationbar from "./Navbar";
import Studentinfo from "./StudentInfo";
import CategoryBar from "./Category";
import Timetable from "./Timetable";
import AcademicInfo from "./AcademicInfo";
import FinancialInfo from "./FinancialInformation";
import Search from "./Search";
import Sidebar from "./Sidebar";
import instance from "../utils/api";

const LARGE_BREAKPOINT = 992;

const Homepage = () => {
  const [activeLink, setActiveLink] = useState("Home");
  const [windowWidth, setWindowWidth] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);
  if (!localStorage.getItem('token')) {
    window.open('/', '_self');
  }
  useEffect(()=> {

    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    Promise.all([
      instance.get("/students/getCurrentCourses"),
      instance.get("/students/getStudentInfo"),
    ])
      .then(([coursesResponse, studentResponse]) => {
        setStudentInfo(studentResponse.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setWindowWidth(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container-fluid p-0 m-0">
      <div className="dashboardLayout row p-0 m-0">
        <div className="col-lg-4 col-xl-3 col-xxl-2 px-0">
          {windowWidth < LARGE_BREAKPOINT ? <></> : <Sidebar activeLink={activeLink} setActiveLink={setActiveLink} studentInfo={studentInfo}/> }
        </div>
        <div className="col-md-12 col-lg-8 col-xl-9 col-xxl-10 px-0">
          <Navigationbar />
          {windowWidth < LARGE_BREAKPOINT ? <CategoryBar activeLink={activeLink} setActiveLink={setActiveLink} /> : <></> }
          {activeLink === "Home" && <Studentinfo />}
          {activeLink === "Register" && <Search />}
          {activeLink === "Time table" && <Timetable />}
          {activeLink === "Academic Information" && <AcademicInfo />}
          {activeLink === "Financial Information" && <FinancialInfo />}
        </div>
      </div>
      <div>
      </div>
    </div>
  );
};

export default Homepage;
